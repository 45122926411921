<template>
  <span class="position-relative">
    <img class="svg-sub4 icon-info unselect" :src="`/static/icon/${icon}.svg`"
         :style="{width:iconSize+'px',height:iconSize+'px'}"
         @mouseover="vTooltip=true"
         @mouseleave="vTooltip=false"/>
    <div class="box-tooltip" v-if="vTooltip"
         :style="`width:${computedWidth}px;left:-${computedLeft}px`">
      <div class="body6-medium">{{ text }}</div>
      <div class="triangle"></div>
    </div>
  </span>
</template>

<script>
  export default {
    name: "ToolTip",
    props: {
      icon: {
        type: String,
        default: 'info'
      },
      text: {
        type: String
      },
      iconSize: {
        type: Number,
        default: 24
      }
    },
    created() {

    },
    computed: {
      computedWidth() {
        if(this.isMobile) {
          return 300
        } else {
          return this.text.length*11
        }
      },
      computedLeft() {
        if(this.isMobile) {
          return 140
        } else {
          return (this.text.length*11)/2 - 10
        }
      }
    },
    data() {
      return {
        vTooltip: false
      }
    },
    methods: {}
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .icon-info
    color $sub5
    vertical-align text-bottom

  .box-tooltip
    background-color $main
    color white
    position absolute
    top -46px
    padding 8px 12px
    border-radius 4px
    text-align center
  .triangle
    position absolute
    left 50%
    bottom -9px
    width 10px
    height 10px
    background-color $main
    transform rotate(45deg) translateX(-50%)
</style>
